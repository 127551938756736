import PropTypes from 'prop-types'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import sleep from '@/lib/util/sleep'
import parseModalConfig from '../util/parseModalConfig'

// The modal context
const ModalContext = createContext({})

// Hook for getting modal context
export const useModalContext = () => {
  const context = useContext(ModalContext)

  return context
}

/**
 * The `ModalProvider`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the react element
 */
function ModalProvider (props) {
  const { children } = props
  const [modalData, setModalData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [shouldModalHide, setShouldModalHide] = useState(false)
  const [modalRef, setModalRef] = useState({})
  const isModalActive = useDelayedActiveState(isModalOpen, 0.1, 0)
  const isModalHidden = useDelayedActiveState(shouldModalHide, 0, 0.1)
  const shouldRender = useDelayedActiveState(isModalOpen, 0, 0.6)
  const promiseRef = useRef(null)
  const timeoutClose = useRef()
  const currentIsModalOpen = useRef(false)
  const isModalContentActive = useDelayedActiveState(
    isModalActive && !isModalHidden,
    0.1,
    0.0
  )
  const timeoutSwap = useRef()
  const sleepTimeout = useRef()

  /**
   * Memo: When the modal data changes, pluck the
   * key parameters for individual access
   */
  const { modalId, modalProps, preventExit, size } = useMemo(
    () => ({
      modalId: modalData?.id,
      preventExit: modalData?.preventExit,
      size: modalData?.size || 'standard',
      modalProps: modalData?.props || {}
    }),
    [modalData]
  )

  /**
   * Swap modal. Used to temporarily hide a modal when it
   * is already open -- rather than hard swap between modals
   * this allows us to transition more smoothly between modals
   */
  const swapModal = useCallback(async () => {
    setShouldModalHide(true)

    if (sleepTimeout.current) {
      // Clear any previous
      sleepTimeout.current.cancel()
    }

    sleepTimeout.current = await sleep(500)
    sleepTimeout.current = null
  }, [])

  /**
   * Show a modal - resolves any previous modal `close`
   * promises and returns the promise to the call. This allows
   * modals to provide feedback in the form of an `action` string
   * that can tell the calling component what to do in reaction
   * to the modal
   */
  const showModal = useCallback(
    async config => {
      if (promiseRef.current) {
        // If have a previous modal promise action cancel it
        promiseRef.current('close')
      }

      if (currentIsModalOpen.current) {
        await swapModal()
      }

      const data = parseModalConfig(config)

      const promise = new Promise(resolve => {
        // Store the promise resolve on a ref to call later
        promiseRef.current = resolve
      })

      setModalData(data)
      setShouldModalHide(false)
      setIsModalOpen(true)

      return promise
    },
    [isModalActive]
  )

  /**
   * On receive an action (option response)
   */
  const onAction = useCallback(action => {
    setIsModalOpen(false)

    promiseRef.current(action)
  }, [])

  /**
   * Hide the modal
   */
  const hideModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  useEffect(() => {
    clearTimeout(timeoutSwap.current)

    timeoutSwap.current = setTimeout(() => {
      currentIsModalOpen.current = isModalOpen
    }, 500)
  }, [isModalOpen])

  /**
   * The context
   */
  const ctx = useMemo(
    () => ({
      isModalActive,
      isModalOpen,
      isModalHidden,
      isModalContentActive,
      modalData,
      modalId: shouldRender ? modalId : null,
      modalRef,
      onAction,
      preventExit,
      setModalRef,
      showModal,
      hideModal,
      size,
      shouldRender,
      props: modalProps
    }),
    [
      isModalActive,
      isModalOpen,
      isModalHidden,
      isModalContentActive,
      modalData,
      modalId,
      modalRef,
      onAction,
      preventExit,
      setModalRef,
      showModal,
      hideModal,
      size,
      shouldRender,
      modalProps
    ]
  )

  /**
   * Effect: On mount - clear any sleep timeout
   */
  useEffect(
    () => () => {
      if (sleepTimeout.current) {
        // Clear any previous
        sleepTimeout.current.cancel()
      }
    },
    []
  )

  /**
   * Effect: On mount
   */
  useEffect(() => {
    if (promiseRef.current) {
      promiseRef.current('close')
      promiseRef.current = null
    }

    return () => {
      clearTimeout(timeoutClose.current)
      clearTimeout(timeoutSwap.current)
    }
  }, [])

  return <ModalContext.Provider value={ctx}>{children}</ModalContext.Provider>
}

/** @type {object} */
ModalProvider.propTypes = {
  children: PropTypes.node
}

/** @type {object} */
ModalProvider.defaultProps = {
  children: null
}

// Memoize
export default memo(ModalProvider)
