import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import Icon from '@/components/core/Icon/Icon'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from '../Alert/Alert'
import inputStyles from '../InputField/InputField.module.scss'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './SelectField.module.scss'

const SelectField = forwardRef((props, forwardedRef) => {
  const {
    className,
    label,
    onChange,
    options,
    theme,
    validate,
    validationErrorMessage,
    value,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const [internalValue, setValue] = useState(value)
  const [isFocus, setIsFocus] = useState(false)
  // Use the form field validator
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const classNameOutput = useMemo(
    () =>
      classNames([
        inputStyles.container,
        inputStyles[`theme-${theme}`],
        inputStyles[`status-${status}`],
        hasValidationError && inputStyles.error,
        hasValidationError && styles.error,
        styles[`theme-${theme}`],
        styles.container,
        isFocus && inputStyles.focus
      ]),
    [isFocus, theme, status, hasValidationError]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      setValue(e.target.value)
      onChange(e)
    },
    [onChange]
  )

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  /**
   * Handle blur
   */
  const handleBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  return (
    <div>
      <label className={classNameOutput}>
        <select
          className={classNames([inputStyles.field, styles.field, className])}
          ref={ref}
          value={value || internalValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...rest}
        >
          {options.map(({ label, value }, i) => (
            <option value={value} key={i}>
              {label}
            </option>
          ))}
        </select>
        <div className={classNames([inputStyles.bg, styles.bg])} />
        <div
          className={classNames([inputStyles.highlight, styles.highlight])}
        />
        <div className={classNames([inputStyles.label, styles.label])}>
          <span>{label}</span>
        </div>
        <div className={styles.arrow}>
          <Icon icon="chevron_down_b" />
        </div>
      </label>
      <Alert isActive={hasValidationError}>{validationErrorMessage}</Alert>
    </div>
  )
})

// Display name
SelectField.displayName = 'SelectField'

/** @type {object} */
SelectField.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
      labelElement: PropTypes.element
    })
  ),
  theme: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.string,
  validate: PropTypes.func,
  validationErrorMessage: PropTypes.string
}

/** @type {object} */
SelectField.defaultProps = {
  className: '',
  onChange: noop,
  options: [],
  theme: 'a',
  label: '',
  value: '',
  validate: null,
  validationErrorMessage: 'Please complete this field.'
}

// Memoize
export default memo(SelectField)
