import PropTypes from 'prop-types'
import { memo } from 'react'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import styles from './Spinner.module.scss'

/**
 * A simple load `Spinner`, supports conditional;
 * rendering via the `isActive` prop, to avoid
 * rendering (and animating) when not needed
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
function Spinner (props) {
  const { className, delayShow, isActive } = props
  const shouldRender = useDelayedActiveState(isActive, delayShow, 0.3)
  const isVisible = useDelayedActiveState(isActive, delayShow + 0.12, 0)

  return (
    shouldRender && (
      <div
        className={`${styles.spinner} ${className}`.trimEnd()}
        data-v={isVisible}
        aria-busy="true"
      />
    )
  )
}

/** @type {object} */
Spinner.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  delayShow: PropTypes.number
}

/** @type {object} */
Spinner.defaultProps = {
  className: '',
  isActive: true,
  delayShow: 0.5
}

// Memoize
export default memo(Spinner)
