import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from '../Alert/Alert'
import inputStyles from '../InputField/InputField.module.scss'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './TextAreaField.module.scss'

/**
 * The `TextAreaField`
 * @param {object} props - the component props
 */
const TextAreaField = forwardRef((props, forwardedRef) => {
  const {
    className,
    label,
    maxLength,
    onChange,
    prefix,
    theme,
    type,
    validate,
    validationErrorMessage,
    value,
    ...rest
  } = props

  const ref = useFallbackRef(forwardedRef)
  const [internalValue, setValue] = useState(value)
  const [isFocus, setIsFocus] = useState(false)
  // Use the form field validator
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const classNameOutput = useMemo(
    () =>
      classNames([
        styles.container,
        inputStyles.container,
        inputStyles[`theme-${theme}`],
        inputStyles[`status-${status}`],
        hasValidationError && inputStyles.error,
        isFocus && inputStyles.focus
      ]),
    [isFocus, theme, status, hasValidationError]
  )
  const labelOutput = useMemo(
    () => (
      <>
        <div>{label}</div>
        {!validate && <span>(optional)</span>}
      </>
    ),
    [label, validate]
  )
  const count = useMemo(() => {
    const total = (value || internalValue).length

    return (
      <>
        <span>{total}</span>
        <span>{maxLength}</span>
      </>
    )
  }, [value, internalValue, maxLength])

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      setValue(e.target.value)
      onChange(e)
    },
    [onChange]
  )

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  /**
   * Handle blur
   */
  const handleBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  return (
    <div>
      <label className={classNameOutput}>
        <textarea
          className={classNames([
            styles.field,
            inputStyles.field,
            className,
            prefix && inputStyles.hasPrefix
          ])}
          ref={ref}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value || internalValue}
          maxLength={maxLength}
          {...rest}
        />
        <div className={inputStyles.bg} aria-hidden="true" />
        <div className={inputStyles.highlight} aria-hidden="true" />
        <div className={inputStyles.prefix}>{prefix}</div>
        <div className={inputStyles.label}>
          <span>{labelOutput}</span>
        </div>
        <div className={inputStyles.footer}>
          <div className={styles.count}>{count}</div>
        </div>
      </label>
      <Alert isActive={hasValidationError}>{validationErrorMessage}</Alert>
    </div>
  )
})

// Display name
TextAreaField.displayName = 'TextAreaField'

/** @type {object} */
TextAreaField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.string,
  theme: PropTypes.string,
  value: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  prefix: PropTypes.node,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  validationErrorMessage: PropTypes.string
}

/** @type {object} */
TextAreaField.defaultProps = {
  className: '',
  label: '',
  type: 'text',
  theme: 'a',
  value: '',
  validate: null,
  onChange: noop,
  prefix: '',
  rows: 4,
  maxLength: 300,
  validationErrorMessage: 'Please complete this field.'
}

export default memo(TextAreaField)
