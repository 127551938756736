import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from '../Alert/Alert'
import Checkbox from '../Checkbox/Checkbox'
import Fieldset from '../Fieldset/Fieldset'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './MultiSelectCheckbox.module.scss'

const MultiSelectCheckbox = forwardRef((props, forwardedRef) => {
  const {
    className,
    label,
    name,
    onChange,
    options,
    validate,
    validationErrorMessage,
    values
  } = props
  const ref = useFallbackRef(forwardedRef)
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const [internalValues, setInternalValues] = useState([])
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.container,
        styles[`status-${status}`],
        hasValidationError && styles.error
      ]),
    [className, status, hasValidationError]
  )

  const outputValues = useMemo(
    () => (values !== null ? values : internalValues),
    [values, internalValues]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(
    optionValue => {
      if (outputValues.includes(optionValue)) {
        setInternalValues(
          outputValues.filter(value => value !== optionValue)
        )

        onChange(outputValues.filter(value => value !== optionValue))

        return
      }

      setInternalValues([...outputValues, optionValue])
      onChange([...outputValues, optionValue])
    },
    [outputValues]
  )

  return (
    <div>
      <Fieldset className={classNameOutput} label={label} validate={validate}>
        <div className={styles.options}>
          {options.map((option, i) => (
            <Checkbox
              key={i}
              label={option}
              checked={outputValues.includes(option)}
              onChange={() => handleChange(option)}
              name={name}
              value={option}
            />
          ))}
        </div>
      </Fieldset>
      <Alert isActive={hasValidationError}>{validationErrorMessage}</Alert>
    </div>
  )
})

// Display name
MultiSelectCheckbox.displayName = 'MultiSelectCheckbox'

/** @type {object} */
MultiSelectCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  validate: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string
}

/** @type {object} */
MultiSelectCheckbox.defaultProps = {
  className: '',
  label: '',
  validate: null,
  validationErrorMessage: 'Please complete this field.',
  options: [],
  values: null,
  name: '',
  onChange: noop
}

// Memoize
export default memo(MultiSelectCheckbox)
