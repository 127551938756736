import { getClosestElementToNode } from './getClosestElementToNode'
import { cleanRect } from './rect'

/**
 * Get the text nodes
 * @param {HTMLElement} el - the node to walk over
 * @returns {Array} the flat array of text nodes within the element
 */
export const getTextNodes = el => {
  const res = []
  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false)
  let n

  // Walk over the nodes
  while ((n = walk.nextNode())) {
    res.push(n)
  }

  return res
}

/**
 *
 * @param {object} range - the range
 * @returns {Array} the rects
 */
function getRangeRects (range) {
  const rects = Array.from(range.getClientRects())

  return rects.map(cleanRect)
}

/**
 *
 * @param {object} range - the range
 * @param {Array} textNodes - the text nodes
 * @returns {Array} the space rects
 */
function getSpaceRects (range, textNodes) {
  return textNodes.reduce((res, textNode) => {
    [...textNode.textContent.matchAll(/ /g)].forEach(match => {
      range.setStart(textNode, match.index)
      range.setEnd(textNode, match.index + 1)

      res.push(cleanRect(range.getBoundingClientRect()))
    })

    return res
  }, [])
}

/**
 *
 * @param {object} range - the range
 * @returns {number} the scale
 */
function getRangeScale (range) {
  const rangeContainer = getClosestElementToNode(range.commonAncestorContainer)
  const sectionCatalogContainer = rangeContainer.closest(
    '.catalog-entry-container'
  )

  if (!sectionCatalogContainer) {
    return 1 // element is not mounted in catalog, assume it is default scale
  }

  const styles = window.getComputedStyle(sectionCatalogContainer)
  const transformMatrix = new DOMMatrix(styles.transform)
  const scale = transformMatrix.a || 1

  return scale
}

/**
 * Simplify the generated rect's into clean rows.
 * This avoids having multiple rects for content on the same row,
 * and instead merges them into a single row entry.
 * @param {Array} textRects - the rects
 * @param {Array} spaceRects - the rects
 * @returns {Array} the rows
 */
const mergeRectsByLine = (textRects, spaceRects) => {
  let { rows } = textRects.reduce(
    (res, rect) => {
      let currentRow = res.rows[res.rowIndex]

      if (
        // No row
        !currentRow ||
        // OR There's a difference between the y and last y
        (currentRow.y !== rect.y &&
          // AND Capture if different sized nested elements (edge-case)
          (currentRow.y < rect.y ||
            currentRow.y + currentRow.height > rect.y + rect.height) &&
          // AND the x diff is above the threshold they would likely side by side
          Math.abs(currentRow.endX - rect.x) > 1)
      ) {
        // Create a new row when the criteria is met
        res.rowIndex += 1

        res.rows[res.rowIndex] = {
          left: rect.left,
          right: rect.right,
          top: rect.top,
          bottom: rect.bottom,
          x: rect.x,
          endX: rect.x,
          y: rect.y,
          width: 0,
          height: rect.height
        }

        currentRow = res.rows[res.rowIndex]
      }

      // Set the end x (used to determine if the next rect is next to the current)
      currentRow.endX = Math.max(currentRow.endX, rect.right)

      // Keep updating the accrued width to get the final width
      currentRow.width = Math.max(0, currentRow.endX - currentRow.x)

      return res
    },
    {
      rowIndex: -1,
      rows: []
    }
  )

  // Sort by largest width + sort the y order (for tidying below)
  rows.sort((a, b) => b.width - a.width).sort((a, b) => b.y - a.y)

  // Second pass at tidying simplified rows to capture any straggling oddities
  // like overlap and tidy up excess whitespace -- while carefully avoiding
  // casual overlap like reduced line height causing rows to visually stack
  rows = rows.reduce((res, row) => {
    if (res.length) {
      const previousRow = res[res.length - 1]

      if (
        (row.y === previousRow.y && row.x === previousRow.x) ||
        (row.y === previousRow.y && row.endX === previousRow.endX)
      ) {
        // Skip rects that might occur when the element
        // has many nested nodes. Purely for tidying
        return res
      }
    }

    // Look for an eol (end of line) match
    const foundWhitespaceIndex = spaceRects.findIndex(
      spaceRect => row.endX === spaceRect.right && row.y === spaceRect.y
    )

    if (~foundWhitespaceIndex) {
      // When we have a match of whitespace against the row end position
      // Sutract the whitespace size from the row width, resulting
      // in a tighter lockup that excludes that whitespace
      row.width -= spaceRects[foundWhitespaceIndex].width
    }

    res.push(row)

    return res
  }, [])

  return rows
}

/**
 * Gets the text row rectangles. This method takes an input array of `textNodes`,
 * specifically those provided from the corresponding `getTextNodes` method.
 * @param {Array} textNodes - the input text nodes
 * @returns {Array} the array of row rectangles
 */
export const getTextRowRects = textNodes => {
  if (!textNodes || !textNodes.length) {
    return []
  }

  // Set the range to cover the text node content
  const range = document.createRange()

  range.setStart(textNodes[0], 0)

  range.setEnd(
    textNodes[textNodes.length - 1],
    textNodes[textNodes.length - 1].length
  )

  // Measure text range rect dimensions, position and scale
  const textRects = getRangeRects(range)
  const scale = getRangeScale(range)

  const spaceRects = getSpaceRects(range, textNodes)

  // Simplify rects to one surrounding rect per line
  const rows = mergeRectsByLine(textRects, spaceRects)

  // Release the range after we are finished with measurement
  range.detach()

  // Reverse y order and cache the row index for posterity
  return {
    scale,
    rowRects: rows.reverse().map((row, i) => {
      row.index = i

      return row
    })
  }
}
