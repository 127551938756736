import PropTypes from 'prop-types'
import { memo } from 'react'
import { getContentComponent } from '..'
/**
 * The `Content` renders content items
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Content (props) {
  const { isActive, items } = props

  return (
    <>{items.map((item, i) => getContentComponent(item, i + 1, isActive))}</>
  )
}

/** @type {object} */
Content.propTypes = {
  isActive: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape)
}

/** @type {object} */
Content.defaultProps = {
  isActive: true,
  items: []
}

// Memoize
export default memo(Content)
