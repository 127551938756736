import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './Radio.module.scss'

/**
 * The `Radio`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const Radio = forwardRef((props, forwardedRef) => {
  const {
    checked,
    className,
    label,
    onChange,
    validate,
    validationErrorMessage,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const [internalChecked, setChecked] = useState(checked || false)
  const [isFocus, setIsFocus] = useState(false)
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const classNameOutput = useMemo(
    () =>
      classNames([
        styles.container,
        isFocus && styles.focus,
        styles[`status-${status}`],
        hasValidationError && styles.error,
        checked !== null
          ? checked && styles.checked
          : internalChecked && styles.checked
      ]),
    [isFocus, internalChecked, checked, status, hasValidationError]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      setChecked(e.target.checked)
      onChange(e)
    },
    [onChange]
  )

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  /**
   * Handle blur
   */
  const handleBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  return (
    <div className={classNameOutput}>
      <label className={styles.label} onFocus={handleFocus} onBlur={handleBlur}>
        <input
          type="radio"
          ref={ref}
          className={`${styles.field} ${className}`}
          checked={checked !== null ? checked : internalChecked}
          onChange={handleChange}
          {...rest}
        />
        <div className={styles.bg} />
        <span>{label}</span>
      </label>
    </div>
  )
})

// Display name
Radio.displayName = 'Radio'

/** @type {object} */
Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  validate: PropTypes.func,
  validationErrorMessage: PropTypes.string
}

/** @type {object} */
Radio.defaultProps = {
  checked: null,
  className: '',
  label: '',
  onChange: noop,
  validate: null,
  validationErrorMessage: 'Please complete this field.'
}

// Memoize
export default memo(Radio)
