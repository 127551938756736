import useLogger from '@/lib/util/useLogger'
import { getGTM } from './util/tagManagerUtils'

const debug = useLogger('Tag Manager', 'teal')

/**
 * The `TagManager` handles Google Tag Manager events across
 * the application. It should only be initialized as part of the
 * consent provided to the `cookiesEvents` utility
 */
class TagManager {
  /** @type {boolean} */
  isInitialized = false

  /** @type {object} */
  walletAddress = null

  /**
   * Initialize
   * @param {object} config - the gtm config to init with
   * @public
   */
  initialize (config = {}) {
    const {
      auth = '',
      dataLayer,
      dataLayerName = 'dataLayer',
      events = {},
      gtmId,
      preview = ''
    } = config

    if (this.isInitialized) {
      // Defensive
      return
    }

    const gtm = getGTM({
      id: gtmId,
      events,
      dataLayer: dataLayer || undefined,
      dataLayerName,
      auth,
      preview
    })

    if (dataLayer) {
      document.head.appendChild(gtm.dataScript)
    }

    document.head.insertBefore(gtm.script(), document.head.childNodes[0])
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
    this.isInitialized = true
  }

  /**
   * Tracks a custom app event. All events are called to
   * a single event trigger (to reduce manual work for adding
   * new events on the fly)
   * @param {object} props - the input props
   * @param {string} props.event - the event name
   * @param {object|string} props.action - the action prop
   * @public
   */
  trackEvent ({ action = {}, event = '' }) {
    if (!this.isInitialized) {
      // Not initialized (no consent)
      return
    }

    if (!window.dataLayer) {
      // Defensive when GTM not ready
      return
    }

    window.dataLayer.push({ event: `app.${event}`, action })
    // Debug
    debug.log('track event:', { event: `app.${event}`, action })
  }
}

// Export as instance
export default new TagManager()
