import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Button from '@/components/core/Button/Button'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import styles from './ButtonActivity.module.scss'

/**
 * The `ButtonActivity`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const ButtonActivity = forwardRef((props, forwardedRef) => {
  const {
    children,
    className,
    isBusy: isBusyInput,
    onClick = noop,
    ...rest
  } = props
  const { userActivityStatus } = useRootContext()
  const ref = useFallbackRef(forwardedRef)

  const isBusy = useMemo(() => {
    if (isBusyInput !== undefined) {
      return isBusyInput
    }

    return !!userActivityStatus
  }, [isBusyInput, userActivityStatus])

  const classNameOutput = useMemo(
    () => classNames(styles.container, className, isBusy && styles.busy),
    [className, isBusy]
  )

  const handleClick = useCallback(
    e => {
      if (isBusy) {
        e.preventDefault()

        return
      }

      onClick()
    },
    [onClick, isBusy]
  )

  return (
    <Button
      ref={ref}
      className={classNameOutput}
      {...rest}
      isBusy={isBusy}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
})

// Display Name
ButtonActivity.displayName = 'ButtonActivity'

/** @type {object} */
ButtonActivity.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isBusy: PropTypes.bool,
  onClick: PropTypes.func
}

export default memo(ButtonActivity)
