/**
 * A debug utility for creating a sleep event (for faux-async/await).
 * Not to be used for any circumstance other than testing
 * @param {number} ms - the sleep time, in milliseconds
 * @returns {Promise} the promise that resolves on completion
 */
const sleep = ms => {
  let timeout
  const promise = new Promise(resolve => {
    timeout = setTimeout(resolve, ms)
  })

  promise.cancel = () => {
    clearTimeout(timeout)
  }

  return promise
}

export default sleep
