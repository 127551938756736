import { ERROR_CODE } from '@/app/util/errorCodes'

// API responses
export const API_RESPONSE = {
  LOGGED_IN: 'logged in',
  SIGNED_UP: 'done sign up',
  NOT_LOGGED_IN: 'not logged in',
  NOT_SIGNED_UP: 'not signed up',
  JWT_EXPIRED: 'auth token expired',
  JWT_INVALID: 'auth token invalid',
  EDIT_SUCCESS: 'mint success',
  EDIT_FAIL: 'mint fail',
  EDIT_MISSING_FIELDS: 'missing required fields',
  FREE_MINT_SUCCESS: 'minted success',
  FREE_MINT_FAIL: 'mint failed',
  FREE_MINT_MISSING_FIELDS: 'missing required fields'
}

export const API_ERROR_RESPONSE_MESSAGE = {
  'invalid referral code': ERROR_CODE.INVALID_REFERRAL,
  'email already exists': ERROR_CODE.ACCOUNT_EMAIL_EXISTS,
  'invalid access code': ERROR_CODE.INVALID_ACCESS_CODE,
  'mint failed': ERROR_CODE.MINT_FAILED,
  'missing required fields': ERROR_CODE.MISSING_FIELDS,
  'local storage token not found': ERROR_CODE.JWT_TOKEN_NOT_FOUND,
  'No user found/token error': ERROR_CODE.BAD_TOKEN,
  'email exists already': ERROR_CODE.ACCOUNT_EMAIL_EXISTS
}
