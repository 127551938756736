let id = 0

/**
 * Get a unique id string
 * @param {string} prefix - the prefix
 * @returns {string} the id string
 */
export const getUniqueIdString = (prefix = 'uid-') => {
  id += 1

  return `${prefix}${id}`
}

/**
 * Get a unique id
 * @returns {number} the id
 */
export const getUniqueId = () => {
  id += 1

  return id
}

export default getUniqueIdString
