import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import { useModalContext } from '../../provider/ModalProvider'
import styles from './Body.module.scss'

const Body = forwardRef((props, forwardedRef) => {
  const { children, className } = props
  const { isModalActive } = useModalContext()
  const isActive = useDelayedActiveState(isModalActive, 0.3, 1)
  const ref = useFallbackRef(forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(styles.container, isActive && styles.active),
    [isActive]
  )

  return (
    <div className={classNameOutput}>
      <div className={styles.wrapper}>
        <div ref={ref} className={classNames(styles.scroll, className)}>
          {children}
        </div>
      </div>
    </div>
  )
})

Body.displayName = 'body'

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default memo(Body)
