import ContentStandard from './ContentStandard/ContentStandard'

const types = {
  standard: ContentStandard,
  html: ContentStandard
}

/**
 * Get a content component
 * @param {object} item - the item
 * @param {number} i - the index
 * @param {boolean} isActive - whether is active
 * @param {object?} additionalProps - additional props to apply to the component
 * @returns {null|React.ReactElement} null or the element
 */
export const getContentComponent = (
  item,
  i,
  isActive,
  additionalProps = {}
) => {
  const Component = types[item.type]

  if (!Component) {
    return null
  }

  return (
    <Component
      key={i}
      {...item}
      {...additionalProps}
      isActive={isActive}
      index={Math.min(5, i)}
    />
  )
}

// Export the types
export default types
