import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import styles from './RadioTab.module.scss'

/**
 * The `RadioTab`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const RadioTab = forwardRef((props, forwardedRef) => {
  const {
    ariaLabel,
    checked,
    children,
    className,
    onChange,
    useTooltip,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const [internalChecked, setChecked] = useState(checked || false)
  const [isFocus, setIsFocus] = useState(false)
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.container,
        isFocus && styles.focus,
        checked !== null
          ? checked && styles.checked
          : internalChecked && styles.checked
      ]),
    [className, isFocus, internalChecked, checked]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      setChecked(e.target.checked)
      onChange(e)
    },
    [onChange]
  )

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  /**
   * Handle blur
   */
  const handleBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  if (useTooltip) {
    return (
      <div className={classNameOutput} {...rest}>
        <label
          className={styles.label}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <input
            type="radio"
            ref={ref}
            className={classNames([styles.field])}
            checked={checked !== null ? checked : internalChecked}
            onChange={handleChange}
          />
          <span>{children}</span>
        </label>
      </div>
    )
  }

  return (
    <div className={classNameOutput} {...rest}>
      <label className={styles.label} onFocus={handleFocus} onBlur={handleBlur}>
        <input
          type="radio"
          ref={ref}
          className={classNames([styles.field])}
          checked={checked !== null ? checked : internalChecked}
          onChange={handleChange}
        />
        <span>{children}</span>
      </label>
    </div>
  )
})

// Display name
RadioTab.displayName = 'RadioTab'

/** @type {object} */
RadioTab.propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  useTooltip: PropTypes.bool
}

/** @type {object} */
RadioTab.defaultProps = {
  ariaLabel: '',
  checked: null,
  className: '',
  children: null,
  onChange: noop,
  useTooltip: false
}

// Memoize
export default memo(RadioTab)
