import { memo, useMemo } from 'react'
import Modal from '@/components/interface/Modal/Modal'
import { useModalContext } from '@/components/interface/Modal/provider/ModalProvider'
import ModalNewsletter from './modal/ModalNewsletter/ModalNewsletter'
import ModalPrivacy from './modal/ModalPrivacy/ModalPrivacy'
import ModalTerms from './modal/ModalTerms/ModalTerms'

/**
 * The `AppModals`
 * @returns {React.ReactElement} the element
 */
function AppModals () {
  const { modalId, modalRef, props } = useModalContext()

  const output = useMemo(() => {
    if (!modalRef.current) {
      return null
    }

    if (modalId === 'app:privacy') {
      return (
        <Modal.Portal>
          <ModalPrivacy />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:terms') {
      return (
        <Modal.Portal>
          <ModalTerms />
        </Modal.Portal>
      )
    }

    if (modalId === 'app:newsletter') {
      return (
        <Modal.Portal>
          <ModalNewsletter />
        </Modal.Portal>
      )
    }
  }, [modalId, props])

  return output
}

export default memo(AppModals)
