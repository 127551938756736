export const ERROR_CODE = {
  UNKNOWN: 'An unknown error occurred. Please try again later.',
  INVALID_REFERRAL: 'The referral code used was invalid or expired.',
  INVALID_ACCESS_CODE: 'The access code used was invalid.',
  ACCOUNT_EMAIL_EXISTS: 'You are already signed up with this email address!',
  BUSY: 'Application busy, please try again shortly.',
  USER_DENIED_TRANSACTION: 'You cancelled the request.',
  USER_DENIED_SIGNATURE: 'You cancelled the request.',
  INSUFFICIENT_FUNDS: 'Insufficient funds in Wallet.',
  NOT_PAID_ENOUGH:
    'Sorry, there was a price calculation issue, please contact us.',
  MINT_FAILED: 'Sorry, unable to mint at this time. Please try again later.',
  MISSING_FIELDS:
    'Sorry, the request was missing important data. Please try again later.',
  BAD_TOKEN: 'Sorry, could not find user. Please try again later.',
  JWT_TOKEN_NOT_FOUND:
    'Sorry, there was a problem with verification. Please try again later.',
  DEFAULT: 'Oops! An error occurred. Please try again later.',
  TIMEOUT: 'Request timed out.'
}

export const BUSY_RESPONSE_STATUS = {
  success: false,
  message: ERROR_CODE.BUSY
}

/**
 * Return a formalized error stats ({ error, message })
 * @param {string} type - the error type
 * @param {string?} message - optional error message
 * @returns {object} the error status
 */
export const getErrorStatus = (type, message) => ({
  success: false,
  message: message || ERROR_CODE[type] || ERROR_CODE.UNKNOWN
})

/**
 * Parse a blockchain error
 * @param {string} input - the stringified version of the error result
 * @returns {object} the error status
 */
export const getBlockchainErrorStatus = input => {
  const errStr = String(input?.message || '').toLowerCase()

  if (
    ~errStr.indexOf('user denied message signature') ||
    ~errStr.indexOf('user rejected')
  ) {
    return getErrorStatus('USER_DENIED_SIGNATURE')
  }

  if (~errStr.indexOf('user denied') || ~errStr.indexOf('user rejected')) {
    return getErrorStatus('USER_DENIED_TRANSACTION')
  }

  if (~errStr.indexOf('insufficient funds')) {
    return getErrorStatus('INSUFFICIENT_FUNDS')
  }

  if (~errStr.indexOf('not paid enough')) {
    return getErrorStatus('NOT_PAID_ENOUGH')
  }

  return {
    success: false,
    message: input?.message || ERROR_CODE.UNKNOWN
  }
}
