import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import classNames from '@/lib/util/classNames'
import { normalizeSizeString } from '@/lib/util/sizes'
import { useModalContext } from '../../provider/ModalProvider'
import styles from './Container.module.scss'

/**
 * The `Container`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Container (props) {
  const { children, className = '', size = 'standard' } = props
  const { isModalContentActive } = useModalContext()
  const isActive = useDelayedActiveState(isModalContentActive, 0.01, 0)

  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.container,
        styles[`size-${normalizeSizeString(size)}`],
        isActive && styles.active
      ]),
    [size, isActive]
  )

  return <div className={classNameOutput}>{children}</div>
}

/** @type {object} */
Container.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node
}

// Memoize
export default memo(Container)
