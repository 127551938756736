import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import styles from './Fieldset.module.scss'

/**
 * The `Fieldset`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Fieldset (props) {
  const { children, className, label, validate } = props
  const classNameOutput = useMemo(
    () => classNames([styles.fieldset, className]),
    [className]
  )
  const labelOutput = useMemo(
    () => (
      <span>
        <div>{label}</div>
        {!validate && <span>(optional)</span>}
      </span>
    ),
    [label, validate]
  )

  return (
    <fieldset className={classNameOutput}>
      <legend>{labelOutput}</legend>
      {children}
    </fieldset>
  )
}

/** @type {object} */
Fieldset.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  validate: PropTypes.func
}

/** @type {object} */
Fieldset.defaultProps = {
  className: '',
  children: null,
  label: '',
  validate: null
}

// Memoize
export default memo(Fieldset)
