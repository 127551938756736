/**
 * Get the closes element to a node
 * @param {HTMLElement} node - the node
 * @returns {HTMLElement} the closest element
 */
export const getClosestElementToNode = node => {
  while (node && node.nodeType !== Node.ELEMENT_NODE) {
    node = node.parentNode
  }
  return node
}
