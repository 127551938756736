import { memo, useEffect, useMemo, useRef, useState } from 'react'
import constants from '@/app/constants'
import fetchJSONData from '@/app/util/fetchJSONData'
import Content from '@/components/content/Content/Content'
import Spinner from '@/components/elements/Spinner/Spinner'
import Modal from '@/components/interface/Modal/Modal'
import styles from './ModalTerms.module.scss'

let cache = null

/**
 * The `ModalTerms`
 * @returns {React.ReactElement} the element
 */
function ModalTerms () {
  const [data, setData] = useState(cache)
  const [error, setError] = useState(null)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    const loadData = async () => {
      const { data, success } = await fetchJSONData(
        `${constants.PATH_DATA}/terms.json?ver=${constants.CACHE_BUST_VERSION}`
      )

      if (!isMounted.current) {
        // No longer mounted
        return
      }

      if (!success) {
        setError(
          'There was a problem loading the data. Please try again later.'
        )

        return
      }

      const res = data

      cache = res

      setData(cache)
    }

    if (!cache) {
      loadData()
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  const output = useMemo(() => {
    if (!data && !error) {
      return <Spinner className={styles.spinner} delayShow={0} />
    }

    if (error) {
      return (
        <div className={styles.error}>
          <p>{error}</p>
        </div>
      )
    }

    return <Content items={data} />
  }, [data, error])

  return (
    <Modal.Container>
      <Modal.Header>Terms & Conditions</Modal.Header>
      <Modal.Body>{output}</Modal.Body>
    </Modal.Container>
  )
}

export default memo(ModalTerms)
