import '@/styles/index.scss'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import RootProvider from '@/app/services/RootProvider/RootProvider'
import { ROOT } from '@/app/thread'
import Loader from '@/components/interface/Loader/Loader'
import App from './App'
import ModalProvider from './components/interface/Modal/provider/ModalProvider'
import PointerProvider from './lib/react/PointerProvider/PointerProvider'

const root = createRoot(ROOT)

root.render(
  <BrowserRouter>
    <ModalProvider>
      <RootProvider>
        <Loader.Provider>
          <PointerProvider>
            <App />
          </PointerProvider>
        </Loader.Provider>
      </RootProvider>
    </ModalProvider>
  </BrowserRouter>
)
