/**
 * Parse a modal config into a predictable dataset
 * @param {object} config - the input config
 * @returns {object} the parsed config
 */
const parseModalConfig = (config = {}) => {
  const data = {
    id: 'default',
    style: 'default',
    preventExit: false,
    size: 'standard',
    props: {},
    ...config
  }

  return data
}

// Export
export default parseModalConfig
