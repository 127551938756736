import PropTypes from 'prop-types'
import { memo } from 'react'
import styles from './Spacer.module.scss'

/**
 * The `Spacer`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Spacer (props) {
  return (
    <div className={styles.spacer}>
      <span />
    </div>
  )
}

Spacer.propTypes = {
  divider: PropTypes.bool
}

Spacer.defaultProps = {
  divider: false
}

export default memo(Spacer)
