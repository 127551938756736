import PropTypes from 'prop-types'
import { memo, useCallback, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Form from '../../Form'
import StepNavigation from '../StepNavigation/StepNavigation'
import styles from './Step.module.scss'

/**
 * The `Step`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Step (props) {
  const {
    children,
    className,
    index,
    isActive,
    label,
    onPrevious,
    onSubmit,
    total,
    ...formProps
  } = props
  const classNameOutput = useMemo(
    () => classNames([className, styles.step, isActive && styles.active]),
    [className, isActive]
  )
  const handleSubmit = useCallback(
    (e, formData) => {
      onSubmit(formData, index)
    },
    [onSubmit, index]
  )

  return (
    <Form className={classNameOutput} {...formProps} onSubmit={handleSubmit}>
      <div className={styles.label}>
        <div className={styles.title}>{label}</div>
      </div>
      <div className={styles.fields}>{children}</div>
      <StepNavigation
        hasPrevious={index > 1}
        hasNext={index < total}
        onPrevious={onPrevious}
      />
    </Form>
  )
}

/** @type {object} */
Step.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
  total: PropTypes.number
}

Step.defaultProps = {
  children: null,
  className: '',
  label: '',
  index: 1,
  isActive: false,
  onNext: noop,
  onPrevious: noop,
  onSubmit: noop,
  total: 1
}

// Memoize
export default memo(Step)
