import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import styles from './Field.module.scss'

/**
 * The `Field`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Field (props) {
  const { Tag, children, className, ...rest } = props
  const attributes = useMemo(
    () => ({
      className: `${styles.field} ${className}`.trimEnd()
    }),
    [className]
  )

  return (
    <Tag {...attributes} {...rest}>
      {children}
    </Tag>
  )
}

/** @type {object} */
Field.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string
}

/** @type {object} */
Field.defaultProps = {
  Tag: 'div',
  children: null,
  className: ''
}

// Memoize
export default memo(Field)
