/**
 * Validate a (non-empty) string
 * @param {string} str - the input value
 * @returns {boolean} whether is valid
 */
export const validateString = str => {
  if (/^\s*$/.test(str)) {
    return false
  }

  return true
}

/**
 * Validate an email
 * @param {string} email - the email string
 * @returns {boolean} whether is valid
 */
export const validateEmail = email => {
  if (!email || !/\S+@\S+\.\S+/.test(email)) {
    return false
  }

  return true
}

export const validateSelect = value => value !== ''

export const validateChecked = (value, element) => element.checked

export const validatePhone = phone => {
  const phoneRegex = /^\+?(?:[0-9] ?){0,14}[0-9]$/

  return phoneRegex.test(phone)
}
