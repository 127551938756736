import PropTypes from 'prop-types'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from './Alert/Alert'
import Checkbox from './Checkbox/Checkbox'
import Field from './Field/Field'
import Fieldset from './Fieldset/Fieldset'
import styles from './Form.module.scss'
import Group from './Group/Group'
import InputField from './InputField/InputField'
import Label from './Label/Label'
import LabelWrapper from './LabelWrapper/LabelWrapper'
import MultiSelectCheckbox from './MultiSelectCheckbox/MultiSelectCheckbox'
import MultiStepForm from './MultiStepForm/MultiStepForm'
import RadioField from './RadioField/RadioField'
import RadioTabs from './RadioTabs/RadioTabs'
import SelectAllCheckboxGroup from './SelectAllCheckboxGroup/SelectAllCheckboxGroup'
import SelectField from './SelectField/SelectField'
import Spacer from './Spacer/Spacer'
import Submit from './Submit/Submit'
import TextAreaField from './TextAreaField/TextAreaField'
import useForm from './hooks/useForm'

// Create the context
const FormContext = createContext({
  addField: noop,
  removeField: noop,
  addValidator: noop,
  removeValidator: noop
})

// Export the context as a hook for quick usage
export const useFormContext = () => {
  const context = useContext(FormContext)

  return context
}

/**
 * The `Form` wrapper
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
function FormComponent (props) {
  const {
    borderRadius,
    children,
    className,
    errorMessage,
    isBusy,
    onBeforeSubmit,
    onSubmit,
    onSubmitError
  } = props
  const { addValidator, removeValidator, validators } = useForm()
  const [hasValidationError, setHasValidationError] = useState(false)
  const ref = useRef()
  const currentValidators = useRef([])
  const classNameOutput = useMemo(
    () =>
      classNames([className, styles.form, styles[`border-${borderRadius}`]]),
    [className, borderRadius]
  )

  /**
   * Handle form submit
   */
  const handleSubmit = useCallback(
    e => {
      e.preventDefault()

      if (isBusy) {
        return
      }

      // Validate all registered fields
      let isValid = true

      onBeforeSubmit()

      currentValidators.current.forEach(validator => {
        if (!validator()) {
          isValid = false
        }
      })

      if (!isValid) {
        setHasValidationError(true)
        onSubmitError()

        // If not valid, cancel submission
        return
      }

      setHasValidationError(false)

      const formData = new FormData(ref.current)
      const formDataObject = {}

      // Convert the form data into a consumable object
      formData.forEach((value, key) => {
        if (!formDataObject[key]) {
          formDataObject[key] = value
        } else {
          if (!Array.isArray(formDataObject[key])) {
            formDataObject[key] = [formDataObject[key]]
          }

          formDataObject[key].push(value)
        }
      })

      onSubmit(e, formDataObject)
    },
    [onBeforeSubmit, onSubmit, onSubmitError, isBusy]
  )

  /**
   * Effect: When the `validators` change, copy into
   * a ref
   */
  useEffect(() => {
    currentValidators.current = validators
  }, [validators])

  /**
   * The context
   */
  const ctx = useMemo(
    () => ({
      addValidator,
      removeValidator,
      isBusy,
      hasValidationError,
      hasCustomError: !!errorMessage,
      errorMessage
    }),
    [isBusy, hasValidationError, errorMessage]
  )

  return (
    <FormContext.Provider value={ctx}>
      <form
        ref={ref}
        className={classNameOutput}
        onSubmitCapture={handleSubmit}
      >
        {children}
      </form>
    </FormContext.Provider>
  )
}

/** @type {object} */
FormComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  onBeforeSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitError: PropTypes.func,
  borderRadius: PropTypes.string,
  isBusy: PropTypes.bool
}

/** @type {object} */
FormComponent.defaultProps = {
  className: '',
  children: null,
  errorMessage: '',
  onBeforeSubmit: noop,
  onSubmit: noop,
  onSubmitError: noop,
  borderRadius: 'a',
  isBusy: false
}

// Memoize
const Form = memo(FormComponent)

// Bind the types
Form.Alert = Alert
Form.Checkbox = Checkbox
Form.Field = Field
Form.Fieldset = Fieldset
Form.Group = Group
Form.InputField = InputField
Form.Label = Label
Form.LabelWrapper = LabelWrapper
Form.MultiSelectCheckbox = MultiSelectCheckbox
Form.RadioField = RadioField
Form.SelectAllCheckboxGroup = SelectAllCheckboxGroup
Form.SelectField = SelectField
Form.Submit = Submit
Form.TextAreaField = TextAreaField
Form.Spacer = Spacer
Form.MultiStepForm = MultiStepForm
Form.RadioTabs = RadioTabs

export default Form
