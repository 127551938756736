import gsap from 'gsap'
import PropTypes from 'prop-types'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import { normalizeSizeString } from '@/lib/util/sizes'
import inputStyles from '../InputField/InputField.module.scss'
import RadioTab from './RadioTab/RadioTab'
import styles from './RadioTabs.module.scss'

const RadioTabs = forwardRef((props, forwardedRef) => {
  const { className, name, onChange, options, size, theme, value } = props
  const ref = useFallbackRef(forwardedRef)
  const [internalValue, setInternalValue] = useState(null)
  const bgRef = useRef()
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        inputStyles.container,
        inputStyles[`theme-${theme}`],
        styles[`theme-${theme}`],
        styles[`size-${normalizeSizeString(size)}`],
        styles.container
      ]),
    [className, theme, size]
  )
  const outputValue = useMemo(
    () => (value !== null ? value : internalValue),
    [value, internalValue]
  )
  const activeItemIndex = useMemo(
    () => options.findIndex(option => option.value === outputValue),
    [options, outputValue]
  )

  /**
   * Effect: When the active index changes, animate the background
   * between the fields
   */
  useEffect(() => {
    const items = Array.from(ref.current.querySelectorAll(`.${styles.tab}`))
    const bgEl = bgRef.current
    const target = items[activeItemIndex]

    if (!target) {
      return
    }

    gsap.killTweensOf(bgEl)

    const bgRect = bgEl.getBoundingClientRect()
    const rect = target.getBoundingClientRect()
    const offset = target.offsetLeft

    bgEl.style.width = `${bgRect.width}px`
    bgEl.style.transform = `translate3d(${bgEl.offsetLeft}px, 0, 0)`
    bgEl.style.position = 'absolute'
    bgEl.style.height = `${rect.height}px`
    bgEl.style.gridColumn = ''

    gsap.to(bgEl, {
      x: offset,
      width: rect.width,
      duration: 0.2,
      onComplete: () => {
        bgEl.style.position = ''
        bgEl.style.transform = ''
        bgEl.style.width = ''
        bgEl.style.height = ''
        bgEl.style.left = ''
        bgEl.style.gridColumn = `${activeItemIndex + 1} / span 1`
      }
    })

    return () => {
      gsap.killTweensOf(bgEl)
    }
  }, [activeItemIndex])

  /**
   * Handle change
   */
  const handleChange = useCallback(
    value => {
      setInternalValue(value)
      onChange(value)
    },
    [onChange]
  )

  return (
    <div className={classNameOutput}>
      <div className={styles.wrapper}>
        <div ref={ref} className={classNames([styles.field, className])}>
          <div ref={bgRef} className={styles.bg} />
          {options.map(({ label, value, ...rest }, i) => (
            <RadioTab
              key={i}
              className={styles.tab}
              label={label}
              checked={outputValue === value}
              onChange={() => handleChange(value)}
              name={name}
              value={value}
              {...rest}
              style={{ gridColumnStart: i + 1, gridRowStart: 1 }}
            >
              {label}
            </RadioTab>
          ))}
        </div>
      </div>
      <div className={classNames([inputStyles.bg, styles.bg])} />
    </div>
  )
})

// Display name
RadioTabs.displayName = 'RadioTabs'

RadioTabs.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  theme: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.string
}

RadioTabs.defaultProps = {
  className: '',
  theme: 'a',
  onChange: noop,
  value: null,
  name: '',
  size: 'standard'
}

// Memoize
export default memo(RadioTabs)
