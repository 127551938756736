import { useCallback, useMemo, useRef, useState } from 'react'

/**
 * The `useForm`
 * @returns {object} the context object
 */
function useForm () {
  const [validators, setValidators] = useState([])
  const currentValidators = useRef([])

  /**
   * Adds a validator callback
   */
  const addValidator = useCallback(callback => {
    currentValidators.current = [...currentValidators.current, callback]
    setValidators(currentValidators.current)
  }, [])

  /**
   * Removes a validator callback
   */
  const removeValidator = useCallback(callback => {
    currentValidators.current = currentValidators.current.filter(
      b => b !== callback
    )

    setValidators(currentValidators.current)
  }, [])

  /**
   * Memo: The context
   */
  const ctx = useMemo(
    () => ({
      validators,
      addValidator,
      removeValidator
    }),
    [validators]
  )

  return ctx
}

// Export
export default useForm
