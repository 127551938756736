import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Content from '@/components/content/Content/Content'
import Form from '@/components/core/Form/Form'
import { isTouch } from '@/lib/util/support'
import { validateEmail, validateString } from '@/lib/util/validators'
import styles from './NewsletterSignUpForm.module.scss'

const CONTENT = [
  {
    type: 'standard',
    content:
      '<p>Stay in the loop with our latest news and alerts! Be the first to know about exciting developments & exclusive offers!</p>'
  }
]

const CONTENT_COMPLETE = [
  {
    type: 'standard',
    content:
      "<h1>We're thrilled to have you on board!</h1><p>Get ready to stay up-to-date with the latest news and receive timely alerts delivered right to your inbox.</p>"
  }
]

/**
 * The `NewsletterSignUpForm`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function NewsletterSignUpForm (props) {
  const { signUpNewsletter } = useRootContext()
  const [isBusy, setIsBusy] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const firstFieldRef = useRef()

  /**
   * On submit
   */
  const onSubmit = useCallback(
    async formData => {
      if (isBusy) {
        // Is buys
        return
      }

      setIsBusy(true)

      const res = await signUpNewsletter(formData)

      setIsBusy(false)

      if (res.success) {
        // Completed
        setIsComplete(true)

        return
      }

      // Failed
      setErrorMessage(res.message)
    },
    [isBusy]
  )

  /**
   * Handle before submit
   */
  const handleBeforeSubmit = useCallback(() => {
    setErrorMessage('')
  }, [])

  /**
   * Handle submit
   */
  const handleSubmit = useCallback(
    (e, formData) => {
      onSubmit(formData)
    },
    [onSubmit]
  )

  /**
   * Handle change of email input
   */
  const handleChangeEmail = useCallback(e => {
    setEmail(e.target.value)
  }, [])

  /**
   * On mount
   */
  useEffect(() => {
    // Focus the first field
    if (!firstFieldRef.current || isTouch) {
      return
    }

    firstFieldRef.current.focus()
  }, [])

  return (
    <>
      {!isComplete && (
        <>
          <div className={styles.content}>
            <Content items={CONTENT} />
          </div>
          <Form
            isBusy={isBusy}
            onBeforeSubmit={handleBeforeSubmit}
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
          >
            <Form.Group display="inline">
              <Form.InputField
                ref={firstFieldRef}
                label="First Name"
                name="firstName"
                validate={validateString}
              />
              <Form.InputField
                label="Last Name"
                name="lastName"
                validate={validateString}
              />
            </Form.Group>

            <Form.Group>
              <Form.InputField
                label="Email"
                name="email"
                type="email"
                value={email}
                validate={validateEmail}
                onChange={handleChangeEmail}
              />
            </Form.Group>

            <Form.Group>
              <Form.Submit size="inherit" tertiary>
                Sign Up
              </Form.Submit>
            </Form.Group>
          </Form>
        </>
      )}
      {isComplete && (
        <>
          <Content items={CONTENT_COMPLETE} />
        </>
      )}
    </>
  )
}

export default memo(NewsletterSignUpForm)
