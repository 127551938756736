import { openDB } from 'idb'
import constants from '@/app/constants'
import useLogger from '@/lib/util/useLogger'
import { getErrorStatus } from './errorCodes'

const debug = useLogger('Fetch JSON', 'orange')

// Create a map to store promises for each URL
const fetchPromises = new Map()

/**
 * Removes the local storage entries when required
 * @param {string} url - the url key
 */
const removeLocalStorageEntries = url => {
  localStorage.removeItem(`${url}_etag`)
  localStorage.removeItem(`${url}_last_modified`)
  // Delete redundant local storage data
  localStorage.removeItem(`${url}_etag_data`)
}

/**
 * Get the existing database and data. Safely attempts to get the
 * database information and gracefully fails when not available
 * (or there was an error)
 * @param {string} url - the url key to get the data from
 * @returns {object} the database result
 */
const getExistingDatabaseData = async url => {
  try {
    const db = await openDB(constants.INDEX_DB_DATABASE_NAME, 1, {
      upgrade (db) {
        const objectStore = db.createObjectStore('jsonCache', {
          keyPath: 'url'
        })

        objectStore.createIndex('url', 'url', { unique: true })
      }
    })
    const existingData = await db.get('jsonCache', url)

    return { db, existingData }
  } catch (err) {
    debug.log('Error getting database:', err)
  }

  return { db: null, existingData: null }
}

/**
 * A safer `fetch` for JSON data that responds in a
 * consistent format
 * @param {string} url - the url
 * @param {object} config - the config
 * @param {boolean} useCache - whether to use the caching mechanisms
 * @returns {Promise} resolves on completion
 */
export default async function fetchJSONData (url, config = {}, useCache = true) {
  // Check if a promise for the URL is already in progress
  if (fetchPromises.has(url)) {
    debug.log('Debounced fetch:', url)

    return fetchPromises.get(url)
  }

  // If not, create a new promise
  const promise = (async () => {
    const { db, existingData } = await getExistingDatabaseData(url)

    if (!existingData || !useCache) {
      removeLocalStorageEntries(url)
    }

    const lastEtag = localStorage.getItem(`${url}_etag`)
    const lastModified = localStorage.getItem(`${url}_last_modified`)

    config.headers = {
      ...config.headers
    }

    if (useCache) {
      if (lastEtag) {
        config.headers['If-None-Match'] = lastEtag
      }

      if (lastModified) {
        config.headers['If-Modified-Since'] = lastModified
      }
    }

    try {
      const response = await fetch(url, config)

      if (response.status === 304) {
        debug.log(`Loaded from (ETag ${lastEtag}) cache:`, url)

        // Not modified, use existing data
        return {
          data: existingData.data,
          success: true
        }
      }

      if (!response.ok) {
        throw new Error('Request failed')
      }

      const data = await response.json()
      const newETag = response.headers.get('ETag')
      const newLastModified = response.headers.get('Last-Modified')

      if (useCache) {
        if (newETag && db) {
          // Got an ETag, store it along with the result
          try {
            await db.put('jsonCache', { url, data })
            localStorage.setItem(`${url}_etag`, newETag)
            debug.log('Updated database for url', url)
          } catch (putError) {
            // Handle storage quota exceeded error gracefully
            debug.log(
              'Error occurred while storing data in IndexedDB:',
              putError
            )

            removeLocalStorageEntries(url)
          }
        }

        if (newLastModified) {
          localStorage.setItem(`${url}_last_modified`, newLastModified)
        }
      }

      return {
        data,
        success: true
      }
    } catch (err) {
      debug.log('Error occurred in fetch:', err)
      removeLocalStorageEntries(url)

      return getErrorStatus('UNKNOWN')
    } finally {
      // Remove the promise from the map when it completes
      fetchPromises.delete(url)
    }
  })()

  // Cache the promise for the URL
  fetchPromises.set(url, promise)

  return promise
}
