import { memo, useCallback, useEffect, useRef } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import { getUniqueId } from '@/lib/util/uniq'
import Body from './components/Body/Body'
import Container from './components/Container/Container'
import Header from './components/Header/Header'
import Portal from './components/Portal/Portal'
import Wrapper from './components/Wrapper/Wrapper'
import { useModalContext } from './provider/ModalProvider'

/**
 * The `Modal` component
 * @returns {React.ReactElement} the element
 */
function ModalComponent () {
  const {
    isModalActive,
    isModalHidden,
    isModalOpen,
    onAction,
    preventExit,
    setModalRef,
    shouldRender,
    size,
    style
  } = useModalContext()
  const { userActivityStatus } = useRootContext()
  const ariaIdLabel = useRef(`modal-${getUniqueId()}-label`)
  const ref = useRef()

  /**
   * Handle close (via the wrapper)
   */
  const handleClose = useCallback(() => {
    // Dispatch the close action
    onAction('close')
  }, [])

  useEffect(() => {
    setModalRef(ref)
  }, [])

  return (
    <Wrapper
      ref={ref}
      ariaIdLabel={ariaIdLabel.current}
      isOpen={isModalOpen}
      isHidden={isModalHidden}
      isActive={isModalActive}
      onClose={handleClose}
      preventExit={preventExit || !!userActivityStatus}
      shouldRender={shouldRender}
      size={size}
      style={style}
    />
  )
}

// Memoize
const Modal = memo(ModalComponent)

Modal.Portal = Portal
Modal.Header = Header
Modal.Container = Container
Modal.Body = Body

// Export
export default Modal
