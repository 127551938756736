import PropTypes from 'prop-types'
import { memo, useCallback } from 'react'
import NavLink from '@/components/interface/NavLink/NavLink'
import OrganicReveal from '@/components/util/OrganicReveal/OrganicReveal'
import classNames from '@/lib/util/classNames'
import { useModalContext } from '../../provider/ModalProvider'
import styles from './Header.module.scss'

/**
 * The `Header`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Header (props) {
  const { children, className } = props
  const { onAction } = useModalContext()

  const handleClick = useCallback(() => {
    onAction('close')
  }, [])

  return (
    <div className={styles.container}>
      <OrganicReveal
        Tag="h1"
        className={styles.title}
        delayShow={0.15}
        active
        classNameContent={classNames(styles.content, className)}
      >
        {children}
      </OrganicReveal>
      <OrganicReveal delayShow={0.25} active>
        <NavLink
          invert
          noMargin={true}
          onClick={handleClick}
          className={styles.close}
        >
          Close
        </NavLink>
      </OrganicReveal>
    </div>
  )
}

/** @type {object} */
Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

/** @type {object} */
Header.defaultProps = {
  className: '',
  children: []
}

// Memoize
export default memo(Header)
