import { gsap } from 'gsap'
import PropTypes from 'prop-types'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import noop from '@/lib/util/noop'
import styles from './MultiStepForm.module.scss'
import Step from './Step/Step'

/**
 * The `MultiStepForm`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function MultiStepForm (props) {
  const { onBeforeSubmit, onSubmit, steps, ...formProps } = props
  const ref = useRef()
  const [currentStep, setCurrentStep] = useState(0)
  const totalSteps = useMemo(() => steps.length, [steps])
  const composedFormData = useRef({})

  /**
   * Scroll to the top of the form (when switching steps)
   */
  const scrollToTop = useCallback(() => {
    const elementPosition = ref.current.getBoundingClientRect().top
    const offsetPosition = elementPosition + scrollY - 50

    gsap.to(window, {
      delay: 0.01,
      duration: 0,
      scrollTo: { y: offsetPosition },
      ease: 'power2.out'
    })
  }, [])

  /**
   * Handle submit
   */
  const handleSubmit = useCallback(
    (formData, stepIndex) => {
      onBeforeSubmit()

      composedFormData.current = {
        ...composedFormData.current,
        ...formData
      }

      if (currentStep < totalSteps - 1) {
        // There is more steps to do
        scrollToTop()
        setCurrentStep(currentStep + 1)

        return
      }

      // User completed form
      onSubmit(null, composedFormData.current)
    },
    [onBeforeSubmit, totalSteps, currentStep]
  )

  /**
   * Handle go to previous step
   */
  const handleGoToPreviousStep = useCallback(() => {
    onBeforeSubmit()

    if (currentStep < 1) {
      return
    }

    scrollToTop()
    setCurrentStep(currentStep - 1)
  }, [onBeforeSubmit, currentStep, totalSteps])

  return (
    <div ref={ref} className={styles.container}>
      {steps.map(({ content, label }, i) => (
        <Step
          key={i}
          label={label}
          isActive={currentStep === i}
          onSubmit={handleSubmit}
          onPrevious={handleGoToPreviousStep}
          index={i + 1}
          total={totalSteps}
          {...formProps}
        >
          {content}
        </Step>
      ))}
    </div>
  )
}

/** @type {object} */
MultiStepForm.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape()),
  onBeforeSubmit: PropTypes.func,
  onSubmit: PropTypes.func
}

/** @type {object} */
MultiStepForm.defaultProps = {
  className: '',
  steps: [],
  onBeforeSubmit: noop,
  onSubmit: noop
}

// Memoize
export default memo(MultiStepForm)
