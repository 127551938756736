/**
 * Resize an rect to fit within specific bounds while maintaining
 * original aspect ratio
 * @param {number} containerWidth - the max width
 * @param {number} containerHeight - the max height
 * @param {number} originalWidth - the original width
 * @param {number} originalHeight - the original height
 * @private
 * @returns {object} the contained rect width/height
 */
export const containRect = (
  containerWidth,
  containerHeight,
  originalWidth,
  originalHeight
) => {
  const ratio = Math.min(
    containerWidth / originalWidth,
    containerHeight / originalHeight
  )

  return {
    width: originalWidth * ratio,
    height: originalHeight * ratio
  }
}

/**
 * Resize a rect to cover the container rect
 * while maintaining original aspect ratio
 * @param {number} containerWidth - the max width
 * @param {number} containerHeight - the max height
 * @param {number} originalWidth - the original width
 * @param {number} originalHeight - the original height
 * @private
 * @returns {object} the covered rect width/height
 */
export const coverRect = (
  containerWidth,
  containerHeight,
  originalWidth,
  originalHeight
) => {
  const ratio = Math.max(
    containerWidth / originalWidth,
    containerHeight / originalHeight
  )

  return {
    width: originalWidth * ratio,
    height: originalHeight * ratio
  }
}

/**
 * Check if a rect is inside another rect
 * @param {object} container - the container rect
 * @param {object} child - the child rect
 * @returns {boolean} true if is inside
 */
export const isInsideRect = (container, child) =>
  child.x + child.width < container.x + container.width &&
  child.x > container.x &&
  child.y > container.y &&
  child.y + child.height < container.y + container.height

/**
 * Rounds the key values of a rect object
 * @param {object} rect - the input rect
 * @returns {object} the rounded rect values object
 */
export const roundRect = rect => ({
  x: Math.round(rect.x),
  y: Math.round(rect.y),
  width: Math.round(rect.width),
  height: Math.round(rect.height)
})

/**
 * Simplify rect
 * @param {object} rect - the input rect
 * @returns {object} the rounded rect values object
 */
export const cleanRect = rect => ({
  left: rect.left,
  right: rect.right,
  top: rect.top,
  bottom: rect.bottom,
  x: rect.x,
  y: rect.y,
  width: Math.max(0, Math.round(rect.width)),
  height: Math.max(0, Math.round(rect.height))
})

export const normalizeRect = (rect, { height, width }) => ({
  x: rect.x / width,
  y: rect.y / height,
  width: rect.width / width,
  height: rect.height / height
})
