import { useCallback, useMemo } from 'react'
import submitSignUpNewsletter from './actions/submitSignUpNewsletter'

/**
 * The `useUser`
 * @returns {object} the context object
 */
export default function useUser () {
  const signUpNewsletter = useCallback(async formData => {
    const res = await submitSignUpNewsletter({
      ...formData
    })

    return res
  }, [])

  const ctx = useMemo(() => ({ signUpNewsletter }), [signUpNewsletter])

  return ctx
}
