import Cookies from 'js-cookie'
import constants from './constants'
import tagManager from './tagManager'

/**
 * Cookies & Events - Configuration to only write and
 * read cookies and track user behavior when user consent
 * has been given.
 *
 * Cookies and events should be managed through this utility
 */

// Initial check to know if cookies are allowed
export let hasConsent = !!Cookies.get('cookiesAllowed')

// The map used to cache blocked cookies
const blockedCookies = new Map()

/**
 * Initialize the tag manager (google tag manager)
 * @private
 */
const initializeTagManager = () => {
  tagManager.initialize({
    gtmId: constants.GTM_ID
  })
}

if (hasConsent) {
  // Has consent on init, initialize the tag manager
  initializeTagManager()
}

/**
 * Get the cookie consent status
 * @returns {boolean} whether cookies are consented
 */
export const getConsentStatus = () => hasConsent

/**
 * Set a cookie
 * @param {string} key - the cookie key
 * @param {any} value - the cookie value
 * @param {object} config - the cookie config
 */
export const setCookie = (
  key,
  value,
  config = {
    expires: 30,
    sameSite: 'None',
    secure: true
  }
) => {
  if (!hasConsent) {
    // If not allowed, cache the cookie into the blocked
    // cookies list, so that we can apply when given consent
    blockedCookies.set(key, {
      value,
      config
    })

    return
  }

  Cookies.set(key, value, config)
}

/**
 * Set essential cookie - cookies that are
 * deemed essential only
 * @param {string} key - the cookie key
 * @param {any} value - the cookie value
 * @param {object} config - the cookie config
 */
export const setEssentialCookie = (
  key,
  value,
  config = {
    expires: 30,
    sameSite: 'None',
    secure: true
  }
) => {
  Cookies.set(key, value, config)
}

/**
 * Gets a cookie by its key
 * @param {string} key - the key
 * @returns {any} the result
 */
export const getCookie = key => {
  if (!hasConsent) {
    // Deny when not allowed
    return null
  }

  return Cookies.get(key)
}

/**
 * Get an essential cookie by its key
 * @param {string} key - the key
 * @returns {any} the result
 */
export const getEssentialCookie = key => Cookies.get(key)

/**
 * Allow cookies - sets the `cookiesAllowed` prop and
 * applies any cookies that were prevented from being
 * applied (before user gave consent)
 */
export const allowCookies = () => {
  if (hasConsent) {
    // Already allowed skip
    return
  }

  hasConsent = true

  // Allow the cookies for future visits
  Cookies.set('cookiesAllowed', true, {
    expires: 60,
    sameSite: 'None',
    secure: true
  })

  // Apply any previously blocked cookies
  blockedCookies.forEach((data, key) => {
    Cookies.set(key, data.value, data.config)
  })

  blockedCookies.clear()

  // Initialize the tag manager
  initializeTagManager()
}
