import PropTypes from 'prop-types'
import { memo, useCallback } from 'react'
import Button from '@/components/core/Button/Button'
import ButtonActivity from '@/components/elements/ButtonActivity/ButtonActivity'
import noop from '@/lib/util/noop'
import Alert from '../../Alert/Alert'
import { useFormContext } from '../../Form'
import styles from './StepNavigation.module.scss'

/**
 * The `StepNavigation`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function StepNavigation (props) {
  const { hasNext, hasPrevious, onPrevious, validationErrorMessage } = props
  const { errorMessage, hasCustomError, hasValidationError, isBusy } =
    useFormContext()

  /**
   * Handle click previous step
   */
  const handleClickPrevious = useCallback(
    e => {
      e.preventDefault()
      onPrevious()
    },
    [onPrevious]
  )

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div>
          {hasPrevious && (
            <Button
              className={styles.button}
              color="c"
              size="inherit"
              icon="arrow_left"
              onClick={handleClickPrevious}
              disabled={isBusy}
            >
              Previous
            </Button>
          )}
        </div>
        <div>
          {hasNext && (
            <Button
              className={styles.button}
              size="inherit"
              icon="arrow_right"
              side="right"
            >
              Next
            </Button>
          )}
          {!hasNext && (
            <ButtonActivity className={styles.button} isBusy={isBusy}>
              Submit
            </ButtonActivity>
          )}
        </div>
      </div>
      <div className={styles.info}>
        <Alert
          useIcon={false}
          className={styles.submitError}
          isActive={hasValidationError || hasCustomError}
        >
          {hasCustomError ? errorMessage : validationErrorMessage}
        </Alert>
      </div>
    </div>
  )
}

/** @type {object} */
StepNavigation.propTypes = {
  validationErrorMessage: PropTypes.node,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onPrevious: PropTypes.func
}

/** @type {object} */
StepNavigation.defaultProps = {
  validationErrorMessage: 'Please complete all fields.',
  hasPrevious: false,
  hasNext: false,
  onPrevious: noop
}

// Memoize
export default memo(StepNavigation)
