import PropTypes from 'prop-types'
import { memo, useCallback, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Form from '../Form'
import styles from './SelectAllCheckboxGroup.module.scss'

/**
 * The `SelectAllCheckboxGroup`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function SelectAllCheckboxGroup (props) {
  const { className, layout, multi, onChange, options, value, ...rest } = props
  const classNameOutput = useMemo(
    () => classNames([className, styles.container, styles[`layout-${layout}`]]),
    [className]
  )

  /**
   * Handle change select all
   */
  const handleAllCheckboxChange = useCallback(
    e => {
      onChange(['all'])
    },
    [options, onChange]
  )

  /**
   * Handle a sub checkbox change (not "all")
   */
  const handleSubCheckboxChange = useCallback(
    e => {
      const checkboxValue = e.target.value

      if (!multi) {
        onChange(e.target.checked ? [checkboxValue] : ['all'])

        return
      }

      const checked = e.target.checked
      let newValue = checked
        ? [...value, checkboxValue]
        : value.filter(val => val !== checkboxValue)

      if (checked) {
        newValue = newValue.filter(key => key !== 'all')
      } else if (!newValue.length) {
        newValue = ['all']
      }

      onChange(newValue)
    },
    [value, multi]
  )

  /**
   * Memo: Whether the `all` is checked
   */
  const isAllChecked = useMemo(() => value.includes('all'), [value])

  return (
    <div className={classNameOutput}>
      <Form.Checkbox
        label="All"
        checked={isAllChecked}
        onChange={handleAllCheckboxChange}
        {...rest}
      />
      {options.map((option, i) => (
        <Form.Checkbox
          key={option.value || i}
          {...rest}
          label={option.label}
          checked={value.includes(option.value)}
          onChange={handleSubCheckboxChange}
          value={option.value}
        />
      ))}
    </div>
  )
}

/** @type {object} */
SelectAllCheckboxGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  multi: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  layout: PropTypes.string
}

/** @type {object} */
SelectAllCheckboxGroup.defaultProps = {
  className: '',
  options: [],
  multi: false,
  value: ['all'],
  onChange: noop,
  layout: 'simple'
}

// Memoize
export default memo(SelectAllCheckboxGroup)
