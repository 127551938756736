import { memo } from 'react'
import Modal from '@/components/interface/Modal/Modal'
import NewsletterSignUpForm from '@/components/interface/NewsletterSignUpForm/NewsletterSignUpForm'

/**
 * The `ModalNewsletter`
 * @returns {React.ReactElement} the element
 */
function ModalNewsletter () {
  return (
    <Modal.Container>
      <Modal.Header>Sign Up For Updates</Modal.Header>
      <Modal.Body>
        <NewsletterSignUpForm />
      </Modal.Body>
    </Modal.Container>
  )
}

export default memo(ModalNewsletter)
