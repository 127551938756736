import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo } from 'react'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import { normalizeSizeString } from '@/lib/util/sizes'
import styles from './Wrapper.module.scss'

/**
 * The `Wrapper`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const Wrapper = forwardRef((props, forwardedRef) => {
  const ref = useFallbackRef(forwardedRef)
  const {
    ariaIdLabel,
    isActive,
    isHidden,
    isOpen,
    onClose,
    preventExit,
    shouldRender,
    size,
    style
  } = props
  const isInteractive = useDelayedActiveState(isOpen, 0.6, 0)
  const classNameOutput = useMemo(
    () =>
      classNames([
        styles.wrapper,
        styles[`style-${style}`],
        styles[`size-${normalizeSizeString(size)}`],
        shouldRender && styles.render,
        isInteractive && styles.interactive,
        preventExit && styles.preventExit,
        isActive && styles.active,
        isHidden && styles.hidden
      ]),
    [
      shouldRender,
      isInteractive,
      preventExit,
      size,
      isActive,
      isHidden,
      style,
      ariaIdLabel
    ]
  )
  const attributes = useMemo(
    () => ({
      role: 'dialog',
      'aria-labelledby': ariaIdLabel,
      tabIndex: isActive ? 0 : -1
    }),
    [isActive, ariaIdLabel]
  )

  /**
   * Handle click to close the modal, by clicking
   * the modal background
   */
  const handleClickClose = useCallback(() => {
    if (!isInteractive || preventExit) {
      // Interactions disable, or exit disabled
      return
    }

    onClose()
  }, [isInteractive, preventExit])

  return (
    <section className={classNameOutput} {...attributes}>
      <button className={styles.background} onClick={handleClickClose} />
      <div className={styles.region}>
        <div ref={ref} className={styles.display} />
      </div>
    </section>
  )
})

// Display name
Wrapper.displayName = 'Wrapper'

/** @type {object} */
Wrapper.propTypes = {
  ariaIdLabel: PropTypes.string,
  onClose: PropTypes.func,
  preventExit: PropTypes.bool,
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  isHidden: PropTypes.bool,
  shouldRender: PropTypes.bool,
  style: PropTypes.string
}

/** @type {object} */
Wrapper.defaultProps = {
  ariaIdLabel: 'modal-label',
  onClose: noop,
  preventExit: false,
  size: 'standard',
  isOpen: true,
  isActive: true,
  isHidden: false,
  shouldRender: true,
  style: 'default'
}

// Memoize
export default memo(Wrapper)
