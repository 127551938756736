import PropTypes from 'prop-types'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Button from '@/components/core/Button/Button'
import Icon from '@/components/core/Icon/Icon'
import OrganicReveal from '@/components/util/OrganicReveal/OrganicReveal'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import classNames from '@/lib/util/classNames'
import { useModalContext } from '../Modal/provider/ModalProvider'
import NavLink from '../NavLink/NavLink'
import styles from './BurgerMenu.module.scss'

/**
 * The `BurgerMenu`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function BurgerMenu (props) {
  const { className, primary, secondary, social } = props
  const { isBurgerMenuOpen, setIsBurgerMenuOpen } = useRootContext()
  const { showModal } = useModalContext()
  const isActive = useDelayedActiveState(isBurgerMenuOpen, 0.1, 0)
  const isRender = useDelayedActiveState(isBurgerMenuOpen, 0, 0.5)
  const ref = useRef()

  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isActive && styles.active,
        isRender && styles.render
      ),
    [className, isActive, isRender]
  )

  useEffect(() => {
    const handleClickAnywhere = e => {
      if (ref.current.contains(e.target) || e.target === ref.current) {
        return
      }

      setIsBurgerMenuOpen(false)
    }

    if (isActive) {
      document.addEventListener('click', handleClickAnywhere)
    }

    return () => {
      document.removeEventListener('click', handleClickAnywhere)
    }
  }, [isActive])

  const handleClickSignUp = useCallback(() => {
    showModal({
      id: 'app:newsletter'
    })

    setIsBurgerMenuOpen(false)
  }, [])

  return (
    <div ref={ref} className={classNameOutput}>
      {isRender && (
        <div className={styles.inset}>
          <div className={styles.display}>
            <div
              className={classNames(styles.card, styles.nav)}
              style={{ '--index': isActive ? 0 : 1 }}
            >
              <div className={styles.content}>
                <ul className={styles.primary}>
                  {primary.map(({ title, ...itemProps }, i) => (
                    <OrganicReveal
                      Tag="li"
                      key={i}
                      delayShow={i * 0.075}
                      active
                    >
                      <NavLink activeStyle="dot" {...itemProps} invert>
                        {title}
                      </NavLink>
                    </OrganicReveal>
                  ))}
                </ul>
                <ul className={styles.secondary}>
                  {secondary.map(({ title, ...itemProps }, i) => (
                    <OrganicReveal
                      Tag="li"
                      key={i}
                      delayShow={0.2 + i * 0.075}
                      active
                    >
                      <NavLink activeStyle="dot" {...itemProps} invert>
                        {title}
                      </NavLink>
                    </OrganicReveal>
                  ))}
                </ul>
                <ul className={styles.social}>
                  {social.map(({ href, icon, title }, i) => (
                    <OrganicReveal
                      Tag="li"
                      key={i}
                      delayShow={0.3 + i * 0.075}
                      active
                    >
                      <a
                        href={href}
                        title={title}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon icon={icon} />
                      </a>
                    </OrganicReveal>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className={styles.buttonCard}
              style={{ '--index': isActive ? 1 : 0 }}
            >
              <Button
                className={styles.button}
                icon="arrow_right"
                onClick={handleClickSignUp}
              >
                Sign Up For Updates
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

/** @type {object} */
BurgerMenu.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.arrayOf(PropTypes.shape()),
  secondary: PropTypes.arrayOf(PropTypes.shape()),
  social: PropTypes.arrayOf(PropTypes.shape())
}

/** @type {object} */
BurgerMenu.defaultProps = {
  className: '',
  primary: [],
  secondary: [],
  social: []
}

// Memoize
export default memo(BurgerMenu)
