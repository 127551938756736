import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useAppData from '@/app/services/hooks/useAppData'
import ButtonBurger from '@/components/interface/ButtonBurger/ButtonBurger'
import NavLink from '@/components/interface/NavLink/NavLink'
import OrganicReveal from '@/components/util/OrganicReveal/OrganicReveal'
import SVGLogo from '@/resources/svg/logo/standard.svg'
import SVGIcon from '@/resources/svg/logo/wide.svg'
import classNames from '@/lib/util/classNames'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import styles from './Header.module.scss'

/**
 * The `Header
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function Header (props) {
  const appData = useAppData()
  const { navBurger, navLeft, navRight } = appData.global.header
  const { social } = appData.global
  const classNameOutput = useMemo(() => classNames(styles.container), [])

  return (
    <>
      <header className={classNameOutput}>
        <div className={styles.bar}>
          <div className={styles.logo}>
            <OrganicReveal active>
              <Link to="/">
                <SVGLogo className={styles.full} />
                <SVGIcon className={styles.icon} />
              </Link>
            </OrganicReveal>
          </div>
          <div className={styles.links}>
            <ul className={styles.group}>
              {navLeft.map(({ title, ...itemProps }, i) => (
                <OrganicReveal key={i} delayShow={0.1 + i * 0.1} active>
                  <NavLink {...itemProps}>{title}</NavLink>
                </OrganicReveal>
              ))}
            </ul>
            <div className={classNames(styles.group, styles.right)}>
              {navRight.map(({ title, ...itemProps }, i) => (
                <OrganicReveal key={i} delayShow={0.2 + i * 0.1} active>
                  <NavLink {...itemProps}>{title}</NavLink>
                </OrganicReveal>
              ))}
            </div>
          </div>
          <OrganicReveal delayShow={0.1} active className={styles.burger}>
            <ButtonBurger />
          </OrganicReveal>
        </div>
      </header>
      <BurgerMenu {...navBurger} social={social} />
    </>
  )
}
