import PropTypes from 'prop-types'
import { forwardRef, memo, useCallback, useMemo, useState } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from '../Alert/Alert'
import Fieldset from '../Fieldset/Fieldset'
import Radio from '../Radio/Radio'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './RadioField.module.scss'

const RadioField = forwardRef((props, forwardedRef) => {
  const {
    className,
    label,
    name,
    onChange,
    options,
    validate,
    validationErrorMessage,
    value
  } = props
  const ref = useFallbackRef(forwardedRef)
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const [internalValue, setInternalValue] = useState(null)
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.container,
        styles[`status-${status}`],
        hasValidationError && styles.error
      ]),
    [className, status, hasValidationError]
  )

  const outputValue = useMemo(
    () => (value !== null ? value : internalValue),
    [value, internalValue]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(value => {
    setInternalValue(value)
    onChange(value)
  }, [])

  return (
    <div>
      <Fieldset className={classNameOutput} label={label} validate={validate}>
        <div className={styles.options}>
          {options.map((option, i) => (
            <Radio
              key={i}
              label={option}
              checked={outputValue === option}
              onChange={() => handleChange(option)}
              name={name}
              value={option}
            />
          ))}
        </div>
      </Fieldset>
      <Alert isActive={hasValidationError}>{validationErrorMessage}</Alert>
    </div>
  )
})

// Display name
RadioField.displayName = 'RadioField'

/** @type {object} */
RadioField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  validate: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string
}

/** @type {object} */
RadioField.defaultProps = {
  className: '',
  label: '',
  validate: null,
  validationErrorMessage: 'Please complete this field.',
  options: [],
  value: null,
  onChange: noop
}

// Memoize
export default memo(RadioField)
