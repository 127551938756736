import constants from '@/app/constants'
import { ERROR_CODE } from '@/app/util/errorCodes'
import fetchJSONData from '@/app/util/fetchJSONData'
import { API_ERROR_RESPONSE_MESSAGE, API_RESPONSE } from './constants'

/**
 * Submit newsletter signup
 * @param {object} formData - the form submission data
 * @returns {object} the response
 */
const submitSignUpNewsletter = async formData => {
  const { email, firstName, lastName } = formData

  let res

  try {
    const { data, success } = await fetchJSONData(
      `${constants.API_V2_URL}/frontend/customers/newsletter`,
      {
        method: 'POST',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          wallet: 'not_provided'
        }),
        headers: {
          'Content-Type': 'application/json',
          'company-id': constants.API_ID
        }
      }
    )

    if (!success) {
      throw new Error('Failed to submit newsletter')
    }

    res = data
  } catch (e) {
    console.error(e)

    return {
      success: false,
      message: ERROR_CODE.UNKNOWN
    }
  }

  if (res?.message === API_RESPONSE.SIGNED_UP) {
    return {
      success: true
    }
  }

  return {
    success: false,
    message: API_ERROR_RESPONSE_MESSAGE[res?.message] || ERROR_CODE.UNKNOWN
  }
}

// Export
export default submitSignUpNewsletter
