import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from '../Form'

/**
 * The `useFormFieldValidator` hook is used to provide
 * validation for child fields of the <Form> container.
 *
 * To work, it requires field to be wrapped inside
 * a `<Form>` element
 * @param {object} ref - the node ref
 * @param {Function?} validate - the validate method
 * @returns {object} the context object
 */
function useFormFieldValidator (ref, validate) {
  const { addValidator, removeValidator } = useFormContext()
  const [status, setStatus] = useState('none')
  const [hasValidationError, setHasValidationError] = useState(false)

  const validateCallback = useCallback(() => {
    const isValid = validate(ref.current.value, ref.current)

    setStatus(isValid ? 'valid' : 'invalid')
    setHasValidationError(!isValid)

    return isValid
  }, [validate])

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      if (!ref?.current) {
        // Defensive
        return
      }

      const isValid = validate(ref.current.value, ref.current)

      setStatus(isValid ? 'valid' : 'invalid')

      if (isValid) {
        // Is valid
        setHasValidationError(false)
      }
    },
    [validate]
  )

  /**
   * Effect: When the handle validate changes
   */
  useEffect(() => {
    if (!validate) {
      return
    }

    addValidator(validateCallback)

    return () => {
      removeValidator(validateCallback)
    }
  }, [validate, validateCallback])

  /**
   * Effect: On mount/unmount
   */
  useEffect(() => {
    if (!validate) {
      return
    }

    ref.current.addEventListener('change', handleChange)

    return () => {
      if (!ref.current) {
        return
      }

      ref.current.removeEventListener('change', handleChange)
    }
  }, [validate])

  /**
   * Memo: The context object
   */
  const ctx = useMemo(
    () => ({
      status,
      hasValidationError
    }),
    [status, hasValidationError]
  )

  return ctx
}

// Export
export default useFormFieldValidator
