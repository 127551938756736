import PropTypes from 'prop-types'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import Alert from '../Alert/Alert'
import useFormFieldValidator from '../hooks/useFormFieldValidator'
import styles from './InputField.module.scss'

/**
 *
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
const InputField = forwardRef((props, forwardedRef) => {
  const {
    className,
    label,
    onChange,
    prefix,
    theme,
    type,
    useOptionalFlag,
    validate,
    validationErrorMessage,
    value,
    ...rest
  } = props

  const ref = useFallbackRef(forwardedRef)
  const [internalValue, setValue] = useState(value)
  const [isFocus, setIsFocus] = useState(false)
  // Use the form field validator
  const { hasValidationError, status } = useFormFieldValidator(ref, validate)
  const classNameOutput = useMemo(
    () =>
      classNames([
        styles.container,
        styles[`theme-${theme}`],
        styles[`status-${status}`],
        !!label && styles.hasLabel,
        hasValidationError && styles.error,
        isFocus && styles.focus
      ]),
    [isFocus, theme, status, hasValidationError, label]
  )
  const labelOutput = useMemo(
    () => (
      <>
        <div>{label}</div>
        {!validate && useOptionalFlag && <span>(optional)</span>}
      </>
    ),
    [label, validate, useOptionalFlag]
  )

  /**
   * Handle change
   */
  const handleChange = useCallback(
    e => {
      setValue(e.target.value)
      onChange(e)
    },
    [onChange]
  )

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  /**
   * Handle blur
   */
  const handleBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  /**
   * Effect: When the props value changes, sync the internal value
   */
  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <div>
      <label className={classNameOutput}>
        <input
          className={classNames([
            styles.field,
            className,
            prefix && styles.hasPrefix
          ])}
          ref={ref}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value || internalValue}
          {...rest}
        />
        <div className={styles.bg} aria-hidden="true" />
        <div className={styles.highlight} aria-hidden="true" />
        <div className={styles.prefix}>{prefix}</div>
        {label && (
          <div className={styles.label}>
            <span>{labelOutput}</span>
          </div>
        )}
      </label>
      <Alert isActive={hasValidationError} align="left">
        {validationErrorMessage}
      </Alert>
    </div>
  )
})

// Display name
InputField.displayName = 'InputField'

/** @type {object} */
InputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  prefix: PropTypes.node,
  theme: PropTypes.string,
  type: PropTypes.string,
  useOptionalFlag: PropTypes.bool,
  validate: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  value: PropTypes.string
}

/** @type {object} */
InputField.defaultProps = {
  className: '',
  label: '',
  onChange: noop,
  prefix: '',
  theme: 'a',
  type: 'text',
  useOptionalFlag: true,
  validate: null,
  validationErrorMessage: 'Please complete this field.',
  value: ''
}

export default memo(InputField)
