import { memo } from 'react'
import { createPortal } from 'react-dom'
import { useModalContext } from '../../provider/ModalProvider'

/**
 * The Modal `Portal`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Portal (props) {
  const { children } = props
  const { modalRef } = useModalContext()

  return createPortal(children, modalRef.current)
}

export default memo(Portal)
