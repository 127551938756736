/**
 * Get the data layer
 * @param {string} dataLayer - the data layer
 * @param {string} dataLayerName - the data layer name
 * @returns {string} the result
 */
export const getDataLayer = (dataLayer, dataLayerName) => `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`

/**
 * Get the tags (iframe, script, dataLayerVar)
 * @param {object} props - the config props
 * @returns {object} the tags config
 */
export const getTags = props => {
  const { auth, dataLayer, dataLayerName, events, id, preview } = props
  const gtmAuth = `&gtmAuth=${auth}`
  const gtmPreview = `&gtmPreview=${preview}`

  const iframe = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${id}${gtmAuth}${gtmPreview}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `

  const script = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(
    1,
    -1
  )}});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');
  `

  const dataLayerVar = getDataLayer(dataLayer, dataLayerName)

  return {
    iframe,
    script,
    dataLayerVar
  }
}

/**
 * Get script - applies the data layer var into a script tag
 * @param {string} dataLayerVars - the data layer config from `getDataLayer`
 * @returns {HTMLScriptElement} the script element
 */
const getScript = dataLayerVars => {
  const script = document.createElement('script')

  script.innerHTML = dataLayerVars

  return script
}

/**
 * Get the GTM
 * @param {object} props - the config props
 * @returns {object} the resulting config
 */
export const getGTM = props => {
  const tags = getTags(props)
  const noScript = () => {
    const noscript = document.createElement('noscript')

    noscript.innerHTML = tags.iframe

    return noscript
  }

  const script = () => {
    const script = document.createElement('script')

    script.innerHTML = tags.script

    return script
  }

  const dataScript = getScript(tags.dataLayerVar)

  return {
    noScript,
    script,
    dataScript
  }
}
