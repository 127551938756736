import { isDebug, isStaging } from '@/app/thread'
import noop from './noop'

const isEnabled = isDebug || isStaging

/**
 * The `useLogger` utility can be used for being
 * able to output namespaced logs
 * @param {string} namespace - the namespace
 * @param {string} color - the color
 * @returns {object} the logger
 */
function useLogger (namespace = 'Log', color = 'black') {
  const label = `%c[${namespace}]`
  const style = `font-weight: bold; color: ${color};`
  const styleError = 'font-weight: bold; color: red;'

  if (!isEnabled) {
    return {
      log: noop,
      error: noop
    }
  }

  return {
    log: (...args) => {
      console.log(label, style, ...args)
    },
    error: (...args) => {
      console.log(label, styleError, ...args)
    }
  }
}

export default useLogger
