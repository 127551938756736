import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import { normalizeSizeString } from '@/lib/util/sizes'
import styles from './Label.module.scss'

/**
 * The `Label`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Label (props) {
  const { children, className, size } = props
  const attributes = useMemo(
    () => ({
      className: `${styles.label} ${
        styles[`size-${normalizeSizeString(size)}`]
      } ${className}`.trimEnd()
    }),
    [className, size]
  )

  return <span {...attributes}>{children}</span>
}

/** @type {object} */
Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string
}

/** @type {object} */
Label.defaultProps = {
  children: null,
  className: '',
  size: 'standard'
}

// Memoize
export default memo(Label)
