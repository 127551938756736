import { memo, useCallback, useMemo, useState } from 'react'
import { allowCookies, hasConsent } from '@/app/cookiesEvents'
import Button from '@/components/core/Button/Button'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import { useModalContext } from '@/components/interface/Modal/provider/ModalProvider'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import classNames from '@/lib/util/classNames'
import styles from './CookieConsent.module.scss'

/**
 * The `CookieConsent`
 * @returns {React.ReactElement} the component
 */
function CookieConsent () {
  const { showModal } = useModalContext()
  const [isDismissed, setIsDismissed] = useState(false)
  const { isReady } = useLoader()
  const isShown = useMemo(
    () => !hasConsent && !isDismissed && isReady,
    [isDismissed, isReady]
  )
  const isRender = useDelayedActiveState(isShown, 0, 0.7)
  const isVisible = useDelayedActiveState(isShown, 1, 0)
  const classNameOutput = useMemo(
    () => classNames(styles.container, isVisible && styles.active),
    [isVisible]
  )

  const handleClickTerms = useCallback(() => {
    showModal({
      id: 'app:terms'
    })
  }, [])

  const handleClickPrivacy = useCallback(() => {
    showModal({
      id: 'app:privacy'
    })
  }, [])

  /**
   * Handle click to reject
   */
  const handleClickReject = useCallback(() => {
    setIsDismissed(true)
  }, [])

  /**
   * Handle click to accept
   */
  const handleClickAccept = useCallback(() => {
    allowCookies()
    setIsDismissed(true)
  }, [])

  if (!isRender) {
    return null
  }

  return (
    <div className={classNameOutput} role="alertdialog">
      <div className={styles.display}>
        <h1>Allow Cookies</h1>
        <p>
          To provide you with the <strong>best experience</strong>, this site
          uses cookies. For more information, please refer to our{' '}
          <a onClick={handleClickTerms}>Terms & Conditions</a> and{' '}
          <a onClick={handleClickPrivacy}>Privacy Policy</a>.
        </p>
        <div className={styles.options}>
          <Button secondary onClick={handleClickReject}>
            Reject
          </Button>
          <Button tertiary onClick={handleClickAccept}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(CookieConsent)
