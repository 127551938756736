import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import ButtonActivity from '@/components/elements/ButtonActivity/ButtonActivity'
import classNames from '@/lib/util/classNames'
import Alert from '../Alert/Alert'
import { useFormContext } from '../Form'
import styles from './Submit.module.scss'

/**
 * The `Submit`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Submit (props) {
  const { children, className, validationErrorMessage, ...rest } = props
  const { errorMessage, hasCustomError, hasValidationError, isBusy } =
    useFormContext()
  const classNameOutput = useMemo(
    () => classNames(className, styles.submit),
    [className]
  )

  return (
    <div className={styles.submitWrapper}>
      <ButtonActivity
        className={classNameOutput}
        type="submit"
        {...rest}
        isBusy={isBusy}
      >
        {children}
      </ButtonActivity>
      <Alert
        useIcon={false}
        className={styles.submitError}
        isActive={hasValidationError || hasCustomError}
      >
        {hasCustomError ? errorMessage : validationErrorMessage}
      </Alert>
    </div>
  )
}

/** @type {object} */
Submit.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  validationErrorMessage: PropTypes.string
}

/** @type {object} */
Submit.defaultProps = {
  children: null,
  className: '',
  validationErrorMessage: 'Please complete all fields.'
}

// Memoize
export default memo(Submit)
