import PropTypes from 'prop-types'
import { memo, useMemo, useRef } from 'react'
import contentStyles from './ContentStandard.module.scss'

/**
 * The `ContentStandard` component is used to render
 * standard HTML based content
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ContentStandard (props) {
  const { align, content, styles } = props
  const ref = useRef()
  const styleAttribute = useMemo(
    () => ({
      textAlign: align,
      ...styles
    }),
    [align, styles]
  )

  return (
    <div
      ref={ref}
      className={contentStyles.content}
      style={styleAttribute}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

/** @type {object} */
ContentStandard.propTypes = {
  align: PropTypes.string,
  content: PropTypes.string,
  isActive: PropTypes.bool,
  styles: PropTypes.shape()
}

/** @type {object} */
ContentStandard.defaultProps = {
  align: 'inherit',
  content: '',
  isActive: false,
  styles: {}
}

export default memo(ContentStandard)
