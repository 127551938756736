import { gsap } from 'gsap'
import PropTypes from 'prop-types'
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import Icon from '@/components/core/Icon/Icon'
import classNames from '@/lib/util/classNames'
import styles from './Alert.module.scss'

/**
 * The `Alert` component
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Alert (props) {
  const { align, children, className, isActive, useIcon } = props
  const ref = useRef()
  const wasActive = useRef(false)
  const classNameOutput = useMemo(
    () => classNames([styles.alert, styles[`align-${align}`]]),
    [align]
  )
  const classNameContentOutput = useMemo(
    () => classNames([className, styles.alertContent]),
    [className]
  )

  /**
   * Effect: When the `isActive` state changes
   */
  useEffect(() => {
    if (isActive === wasActive.current) {
      return
    }

    wasActive.current = isActive

    gsap.killTweensOf(ref.current)

    if (isActive) {
      gsap.to(ref.current, {
        height: 'auto',
        duration: 0.2
      })

      return
    }

    gsap.set(ref.current, {
      height: 0
    })
  }, [isActive])

  /**
   * Layout Effect: On mount/unmount
   */
  useLayoutEffect(() => {
    wasActive.current = false

    gsap.set(ref.current, { height: 0 })

    return () => {
      gsap.killTweensOf(ref.current)
    }
  }, [])

  return (
    <div ref={ref} className={classNameOutput}>
      <div className={classNameContentOutput}>
        {useIcon && (
          <div className={styles.alertIcon}>
            <Icon icon="alert" />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

/** @type {object} */
Alert.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  useIcon: PropTypes.bool
}

/** @type {object} */
Alert.defaultProps = {
  align: 'center',
  children: null,
  className: '',
  isActive: false,
  useIcon: true
}

// Memoize
export default memo(Alert)
