import PropTypes from 'prop-types'
import { memo } from 'react'

/**
 * The `LabelWrapper`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function LabelWrapper (props) {
  const { children, ...rest } = props

  return <label {...rest}>{children}</label>
}

/** @type {object} */
LabelWrapper.propTypes = {
  children: PropTypes.node
}

/** @type {object} */
LabelWrapper.defaultProps = {
  children: null
}

// Memoize
export default memo(LabelWrapper)
